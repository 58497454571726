export default function orphan() {
  const els = document.querySelectorAll(".no-orphan");

  els.forEach((el) =>
    el.querySelectorAll("p").forEach((p) => {
      const splitText = p.innerHTML.split(" ");
      const end = splitText.pop();
      const end2 = splitText.pop();
      p.innerHTML = splitText.join(" ") + "&nbsp;" + end2 + "&nbsp;" + end;
    })
  );
}
