import barba from "@barba/core";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Slider from "./js/Slider";
import Headroom from "headroom.js";
import lazySizes from "lazysizes";
import orphan from "./js/orphan";
import Video from "./js/Video";

import "./app.scss";

barba.init({
  debug: true,
  logLevel: "error",
  timeout: 5000,
});

barba.hooks.beforeEnter((data) => {});

barba.hooks.after((data) => {
  const hash = data?.next?.url?.hash ?? "";
  const target = document.querySelector(hash.length ? "#" + hash : undefined);
  if (target) {
    window.scroll(0, window.pageYOffset + target.getBoundingClientRect().top);
  } else {
    window.scroll(0, 0);
  }
  runActions();
});

runActions();

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

function runActions() {
  action("Video", Video);
  action("Slider", Slider);
  console.log("runActions");
  const headroomElement = document.querySelector("#header");
  const headroom = new Headroom(headroomElement, {
    offset: 200,
  });
  headroom.init();

  const mobbtn = document.querySelector(".menu-btn");
  const meganav = document.querySelector(".mega-nav");

  const closeBtn = document.querySelector(".close-btn");
  if (closeBtn) {
    closeBtn.addEventListener("click", function () {
      meganav.classList.remove("open");
    });
  }
  mobbtn?.addEventListener("click", function () {
    meganav.classList.toggle("open");
  });
  meganav.querySelectorAll("a").forEach((a) =>
    a.addEventListener("click", () => {
      if (a.href.indexOf("#") !== -1) meganav.classList.toggle("open");
    })
  );

  orphan();

  const oneOff = document.querySelector(".one-off");
  const oneOffForm = document.querySelector("#donate-form");

  const monthBtn = document.querySelector(".monthly-btn");
  const monthlyForm = document.querySelector("#monthly-form");

  if (oneOff) {
    oneOff.addEventListener("click", function () {
      oneOffForm.classList.add("open");
      monthlyForm.classList.remove("open");
    });
  }
  if (monthBtn) {
    monthBtn.addEventListener("click", function () {
      monthlyForm.classList.add("open");
      oneOffForm.classList.remove("open");
    });
  }

  document
    .querySelectorAll(".dropdown-toggle")
    .forEach((tog) =>
      tog.addEventListener("click", (e) =>
        tog
          .closest(".dropdown")
          .querySelector(".dropdown-content")
          .classList.toggle("hidden")
      )
    );
}
